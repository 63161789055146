@import '~antd/dist/antd.css';

@import url('https://fonts.googleapis.com/css2?family=Fjalla+One&family=Lobster&family=Rubik+Moonrocks&display=swap');

.App {
  /* text-align: center; */
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: 'Rubik Moonrocks', cursive;
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}
/*Homepage==================================================================================START */
.Banner {
  width: 100%;
  height: 60px;
  background-color: #f7f6f2;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 10px 10px 10px 40px;
}

.Banner-Links-Box {
  display: flex;
  /* flex-direction: row; */
  justify-content: space-between;
  align-items: center;
  height: 40px;
  width: 200px;
  /* background-color: aqua; */
}

.Card-Container {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: space-evenly;
  padding: 20px;
  margin-top: 20px;
}

.Card {
  width: 30vw;
  background-color: #f7f6f2;
}

.Card-Image {
  width: 100%;
  object-fit:cover;
}

.Card-Title {
  font-family: 'Lobster', cursive;
  font-weight: bold;
}

.Card-Description {
  font-family: 'Fjalla One', sans-serif;
}

.Heading-Box {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  /* background-color:rgb(255, 0, 43); */
}

.Link-Box {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  /* background-color:aqua; */
}

.Links {
  margin: 0;
  padding: 0;
  color: #f527e4;
  font-family: 'Fjalla One', sans-serif;
}

/*Homepage==================================================================================END */

/*DescriptionPage==================================================================================START */
.Description-Card {
  width: 70vw;
  background-color: #f7f6f2;
}

.Description-Card-Image {
  width: 100%;
  height: 60vh;
  object-fit:fill;
}

.Description-Card-Container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 10px;
}

.Carousel {
  width: 70vw;
  margin: 0 auto;
}
.Carousel-Item {
  margin: 0 auto;
}
/*DescriptionPage==================================================================================END */

/*AboutPage==================================================================================START */
.About-Container {
  display: flex;
  flex-direction: row;
  padding: 20px;
}
.Me {
  height: 70vh;
  object-fit: contain;
}

.Self-Description {
  padding: 20px;
  font-family: 'Fjalla One', sans-serif;
  font-size: 20px;
  font-weight: 100;
}
/*AboutPage==================================================================================END */
.Footer {
  bottom: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 10px;
}

.Footer-Text {
  margin: 0;
  padding: 0;
  font-family: 'Fjalla One', sans-serif;
}
/*Media Querries==================================================================================START */
@media screen and (max-width: 800px) {
  .Card-Container {
    flex-direction: column;
    align-items: center;
  }
  .Card {
    width: 90vw;
    margin-top: 20px;
  }
  .Description-Card {
    width: 100vw;
    background-color: #f7f6f2;
  }
  
  
  .Carousel {
    width: 100vw;
    margin: 0 auto;
  }
}
@media screen and (max-width: 900px) {
.About-Container {
  display: flex;
  flex-direction: column;
  padding: 20px;
}
}
@media screen and (max-width: 400px) {
  .Banner {
    height: 120px;
    padding: 10px 10px 10px 40px;
  }
  
  .Banner-Links-Box {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-end;
    height: 40px;
    width: 200px;
    /* background-color: aqua; */
  }
}